<template lang="pug">
  .user-manage.d-none
    .container
      h1 支払方法変更
      form.needs-validation(@submit="exec" ref="form" method="POST" novalidate)
        .form-group
          .row.justify-content-md-center
            input.btn.btn-primary.col.col-md-3(type="submit" value="支払方法変更" v-bind:disabled="isDisabled" ref="submit")
        input(type="hidden" name="pay_method" value="credit")
        input(type="hidden" name="merchant_id" value="74522")
        input(type="hidden" name="service_id" value="001")
        input(type="hidden" name="cust_code" ref="userId")
        input(type="hidden" name="terminal_type" value="0")
        input(type="hidden" name="success_url" ref="successUrl")
        input(type="hidden" name="cancel_url" ref="cancelUrl")
        input(type="hidden" name="error_url" ref="errorUrl")
        input(type="hidden" name="pagecon_url" ref="pageconUrl")
        input(type="hidden" name="request_date" ref="requestDate")
        input(type="hidden" name="limit_second" value="600")
        input(type="hidden" name="sps_hashcode" ref="hashcode")
</template>

<script>
import { createHash } from 'crypto'

export default {
  data() {
    return {
      message: '',
      isDisabled: false,
    }
  },
  mounted() {
    this.$refs.form.action = process.env.VUE_APP_PAY_CREDIT

    this.$refs.successUrl.value = location.origin + '/user/payment/upd_complete'
    this.$refs.cancelUrl.value = location.origin + '/user/payment/info'
    this.$refs.errorUrl.value = location.origin + '/'
    this.$refs.pageconUrl.value = process.env.VUE_APP_API_GATEWAY + '/api-pay'

    this.$refs.submit.click()
  },
  methods: {
    exec() {
      this.isDisabled = true

      this.messages = ''

      //日付取得
      let date = new Date ()

      let year = date.getFullYear() 	// 年
      let month = ('0' + (date.getMonth() + 1)).slice(-2)	// 月
      let day = ('0' + date.getDate()).slice(-2) //日
      let hour = ('0' + date.getHours()).slice(-2) 	// 時
      let minute = ('0' + date.getMinutes()).slice(-2)	// 分
      let second = ('0' + date.getSeconds()).slice(-2) 	// 秒

      this.$refs.requestDate.value = '' + year + month + day + hour + minute + second
      this.$refs.userId.value = this.$user.userId

      // ハッシュ値設定
      this.createCheckHash(this.$refs.form)
      
    },
    createCheckHash(e) {
      const hash = process.env.VUE_APP_PAY_HASH 
      let str = ''
      e.children.forEach(elm => {
        if (elm.type !== 'hidden') return
        str += elm.value
      })
      let shasum = createHash('sha1')
      shasum.update(str + hash)
      this.$refs.hashcode.value = shasum.digest('hex')
    }
  },
}
</script>